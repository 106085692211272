import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "../../styles/colors"
import Socials from "../socials"
import AppStoreLinks from "components/appStoreLinks/appStoreLinks"
import Link from "next/link"
import Spacer from "components/spacer"
import { useRouter } from "next/router"
import FACPaymentLogos from "components/layout/fac-payment-logos"

export default function Footer() {
  const router = useRouter()
  const { pathname } = router

  return (
    <Wrapper>
      <InnerContainer>
        <Logo src="/logo.png" />
        <MiniGrid count={1}>
          <Contacts>
            <Copy>
              Contact us:{" "}
              <ExternalLinkItem href="mailto:hi@unqueue.app">
                hi@unqueue.app
              </ExternalLinkItem>
            </Copy>
            {!pathname?.includes("/store") && (
              <>
                <Spacer size={16} />
                <Socials />
              </>
            )}
            {pathname?.includes("/store") && (
              <>
                <Spacer size={5} />
                <Footnote>
                  Heads up! This email address puts you in touch with
                  Unqueue. We don’t sell products; we’re a software company. If
                  you’re looking for info from a store, you can message them
                  directly through the Chat feature on the Unqueue shopping app.
                </Footnote>
              </>
            )}
          </Contacts>
        </MiniGrid>
        <MiniGrid count={1}>
          {/* <Mailing>
                        <h3 style={{color: 'white', fontSize: 20, fontWeight: 500, marginBottom: 10}}>Join our mailing list</h3>
                        {
                            subscribed ?
                                <p>Thank you for subscribing!</p>
                                :
                                <>
                                    <div style={{position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                                        <Input
                                            placeholder="you@email.com"
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
            
                                        />
                                        
                                        <SubscribeBtn onClick={handleSubmit}>
                                            {loading ?
                                                <Loader type="Oval" color={colors.businessPrimary} height={22} width={22} />
                                                :
                                                <BsArrowRight color="black" size={28} />
                                            }
                                        </SubscribeBtn>
                                    </div>
                                    <p style={{color: '#787878', fontSize: 15, fontWeight: 500, lineHeight: 1, marginBottom: 10}}>You can unsubscribe at any time using the link in our emails. For more details, review our <Link style={{color: 'inherit'}} href="/privacy">privacy policy.</Link></p>
                                </>
                        }
                    </Mailing> */}
          <Links>
            <ExternalLinkItem
              href="https://deliver.unqueue.app"
              target="_blank"
              rel="noreferrer"
            >
              Deliver with Unqueue
            </ExternalLinkItem>
            <ExternalLinkItem
              href="https://unqueue.app/sell"
              target="_blank"
              rel="noreferrer"
            >
              Sell with Unqueue
            </ExternalLinkItem>
            <ExternalLinkItem
              href="https://feedback.unqueue.app"
              target="_blank"
              rel="noreferrer"
            >
              Feedback
            </ExternalLinkItem>
            <ExternalLinkItem
              href="https://support.unqueue.app"
              target="_blank"
              rel="noreferrer"
            >
              Support
            </ExternalLinkItem>
            <ExternalLinkItem
              href="https://unqueue.app/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </ExternalLinkItem>
            <ExternalLinkItem
              href="https://unqueue.app/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </ExternalLinkItem>
            <LinkItem href="/refund-policy">
              <a style={{ color: "white", textDecoration: "underline" }}>
                Refund Policy
              </a>
            </LinkItem>
          </Links>
        </MiniGrid>
        <MiniGrid margin={false} count={2}>
          <AppStoreLinks />
          <div>
            {/* <Socials /> */}
            <Spacer size={20} />
            <FACPaymentLogos />
          </div>
        </MiniGrid>
      </InnerContainer>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  background: ${colors.base};
  color: white;
`
const InnerContainer = styled.div`
  padding: 50px 20px;
  display: grid;
  grid-template-rows: repeat(5, auto);
  gap: 25px;
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 800px) {
    grid-template-columns: repeat(4, auto);
  }
`
const MiniGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(${props => props.count}, min-content);
  gap: 15px;
  @media (min-width: 800px) {
    margin-top: ${props => props.margin && "31px"};
  }
`
const Logo = styled.img`
  margin: 0;
  max-width: 140px;
  width: 100%;
  @media (min-width: 800px) {
    grid-column-start: span 4;
  }
`
const Copy = styled.p`
  font-size: 20px;
  line-height: 1.3;
  max-width: 40ch;
  margin: 0;
`
const Footnote = styled.p`
  font-size: 15px;
  line-height: 1.3;
  max-width: 40ch;
  margin: 0;
  opacity: 0.7;
  font-weight: 300;
`
const Mailing = styled.div``
const Input = styled.input`
  padding: 10px 15px;
  font-size: 18px;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
`
const Contacts = styled.div``
const Links = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
`
const LinkItem = styled(Link)`
  color: white;
  text-decoration: underline;
  font-family: "PhantomSans";
`
const ExternalLinkItem = styled.a`
  color: white;
  text-decoration: underline;
  font-family: "PhantomSans";
`
const SubscribeBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  margin-bottom: 5px;
`
