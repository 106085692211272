import React, { useReducer, useContext, useEffect } from "react"
import { PICKUP_METHODS } from "lib/constants"
import moment from "moment"
import { getUniqueId } from "../lib/utils"
import {
  ADD_ITEM_TO_CART,
  ADD_DISCOUNT_CODE,
  REMOVE_ITEM_FROM_CART,
  TOGGLE_SIDEBAR_HIDDEN,
  CLEAR_CART,
  CLEAR_CART_ITEMS,
  CREATE_ORDER_OBJECT,
  UPDATE_PICKUP_DETAILS,
  UPDATE_PAYMENT_DETAILS,
  UPDATE_STORE_DELIVERY_FEE,
  UPDATE_CURRENT_STORE_DETAILS,
  UPDATE_DELIVERY_ADDRESS,
  UPDATE_GUEST_USER_DETAILS,
  UPDATE_CART_TOTAL,
  UPDATE_USER_AUTH,
  SET_TIME_WINDOW,
  SET_DELIVERY_WINDOW,
  SET_CUSTOM_CHECKOUT,
  UPDATE_PICKUP_WINDOW,
  SET_IS_DELIVERY_DISCOUNT,
  UPDATE_USER_DATA,
  SET_SPECIAL_NOTES,
  UPDATE_MISSING_ITEMS_PREF,
} from "./actionTypes"
import { useSubtotal } from "lib/hooks"

const GlobalStateContext = React.createContext()
const GloablDispatchContext = React.createContext()

const initialState = {
  cashAmount: 0,
  carDetails: {
    licensePlate: "",
    carModel: "",
  },
  currentStoreDetails: {},
  deliveryFee: 0,
  deliveryAddress: {
    address: "",
  },
  deliveryWindow: "",
  discount: {
    value: 0,
  },
  isDeliveryDiscount: false,
  guestUserDetails: {},
  isCustomCheckout: false,
  missingItemsPreference: null,
  order: {},
  paymentMethod: "",
  pickupMethod: "",
  shoppingCart: [],
  shoppingCartIds: {},
  sidebarHidden: true,
  specialNotes: "",
  pickupTimeDate: false,
  timeWindow: null,
  total: 0,
  userAuth: {},
  userData: {},
}

const handleUpdateCart = (state, item, createNew = false) => {
  // Check is item exist in cart
  // console.log(state)

  let isItemInCart = state.shoppingCartIds.hasOwnProperty(item.cartItemId)
  let newCartIds = { ...state.shoppingCartIds }

  if (isItemInCart && createNew === false) {
    let newShoppingCart = state.shoppingCart.map(cartItem => {
      if (cartItem.cartItemId && cartItem.cartItemId === item.cartItemId) {
        cartItem = { ...item }
      }

      // users with items in the cart may not have a cartItemId we check and assign
      // a new cartItemId
      if (!cartItem.cartItemId && cartItem.id === item.id) {
        let uniqueId = getUniqueId()
        cartItem = { ...item, cartItemId: uniqueId }
        newCartIds[uniqueId] = { ...item }
      }
      return cartItem
    })
    return {
      ...state,
      shoppingCart: newShoppingCart,
      shoppingCartIds: newCartIds,
    }
  } else {
    let uniqueId = getUniqueId()
    newCartIds[uniqueId] = { ...item, cartItemId: uniqueId }
    // console.log({ newCartIds })
    let newShoppingCart = [
      ...state.shoppingCart,
      {
        ...item,
        cartItemId: uniqueId,
        storeId: state.currentStoreDetails.id,
        storeName: state.currentStoreDetails.name,
      },
    ]
    return {
      ...state,
      shoppingCart: newShoppingCart,
      shoppingCartIds: newCartIds,
    }
  }
}

const handleUpdateStoreDeliveryFee = (state, item) => {
  return { ...state, deliveryFee: item }
}

const handleRemoveFromCart = (state, itemToRemove) => {
  let cartIds = { ...state.shoppingCartIds }

  // handle legacy cart items that dont have a cartItemId
  if (!itemToRemove.cartItemId) delete cartIds[itemToRemove.id]
  delete cartIds[itemToRemove.cartItemId]

  let newCartItems = state.shoppingCart.filter(item => {
    if (!itemToRemove.cartItemId) return item.id !== itemToRemove.id
    return item.cartItemId !== itemToRemove.cartItemId
  })

  return { ...state, shoppingCart: newCartItems, shoppingCartIds: cartIds }
}

const handleClearCart = state => {
  return {
    ...state,
    carDetails: {
      licensePlate: "",
      carModel: "",
    },
    cashAmount: 0,
    currentStoreDetails: {},
    deliveryAddress: {
      address: "",
    },
    deliveryWindow: "",
    deliveryFee: 0,
    discount: {
      value: 0,
    },
    isDeliveryDiscount: false,
    missingItemsPreference: null,
    order: 0,
    paymentMethod: "",
    pickupMethod: "",
    specialNotes: "",
    shoppingCart: [],
    shoppingCartIds: {},
    pickupTimeDate: false,
    timeWindow: null,
    total: 0,
  }
}

const handleClearCartItems = state => {
  return {
    ...state,
    cashAmount: 0,
    deliveryAddress: {
      address: "",
    },
    deliveryWindow: "",
    deliveryFee: 0,
    discount: {
      value: 0,
    },
    isDeliveryDiscount: false,
    missingItemsPreference: null,
    order: 0,
    paymentMethod: "",
    pickupMethod: "",
    specialNotes: "",
    shoppingCart: [],
    shoppingCartIds: {},
    pickupTimeDate: false,
    timeWindow: null,
    total: 0,
  }
}

const handleCreateOrder = state => {
  // Create cart object
  let cart = state.shoppingCart.map(cartItem => {
    const variationsWithSelectedOptions = cartItem.variations.filter(
      el => el.selectedOptions.length
    )
    // No sign of add-ons on variations
    if (
      cartItem.addons.length < 1 &&
      variationsWithSelectedOptions.length < 1
    ) {
      console.log("No add-ons or variations found, returning default cart item")
      return { ...cartItem, addonsPrice: 0 }
    }

    // As we transition to variations, we need to do a check for whether this cart item has
    // a variation or add-on..
    if (variationsWithSelectedOptions?.length > 0) {
      // Get addon total for item
      let addonTotal = 0
      for (const variation of variationsWithSelectedOptions) {
        for (const option of variation.selectedOptions) {
          addonTotal += option.price
        }
      }
      const addons = variationsWithSelectedOptions
        .map(
          addon =>
            `${addon.title}: ${addon.selectedOptions
              .map(
                option =>
                  `${option.name} ${option.price ? `($${option.price})` : ""}`
              )
              .join(", ")}`
        )
        .join(", ")

      return { ...cartItem, addonsPrice: addonTotal, note: addons }
    } else {
      // Get addon total for item
      let addonTotal = cartItem.addons.reduce((total, current) => {
        if (current.applyPrice) {
          total = total + current.price
        }
        return total
      }, 0)

      //Get the addons in note format
      let addons = cartItem.addons.map(addon => addon.name).join(", ")

      return { ...cartItem, addonsPrice: addonTotal, note: addons }
    }
  })

  let { guestUserDetails, userAuth, userData } = state
  let currentCurrency = "TTD"
  if (typeof window !== "undefined") {
    currentCurrency = JSON.parse(
      window.sessionStorage.getItem("currentCurrency")
    )
  }
  let name =
    guestUserDetails?.fullName ?? `${userData?.firstName} ${userData?.lastName}`
  let email = guestUserDetails.email ?? userData?.email
  let phone = guestUserDetails?.phoneNumber ?? userData?.phoneNumber
  let id = userAuth.uid || `${guestUserDetails.fullName}-${Date.now()}`

  const subtotal = useSubtotal(state.shoppingCart)
  const marketFee = state.currentStoreDetails.marketCompany
    ? Math.ceil(subtotal * 0.05)
    : 0

  let newOrder = {
    order: {
      cart,
      type: state.pickupMethod,
      total: state.total,
      paymentMethod: state.paymentMethod,
      deliveryAddress: { ...state.deliveryAddress },
      customerLicense: state.carDetails.licensePlate,
      cashAmount: Number(state.cashAmount ?? 0),
      carDetails: state.carDetails.carModel,
      specialNotes: state.specialNotes,
      discountApplied: {
        ...state.discount,
      },
      source: "web",
      missingItemsPreference: state.missingItemsPreference,
      cartCurrency: currentCurrency,
    },
    shopperData: {
      email: email ?? "receipts@unqueue.app",
      name: name ?? "Unqueue Shopper",
      id: id,
      phone: phone,
    },
    store: {
      ...state.currentStoreDetails,
      address: {
        ...state.currentStoreDetails.address,
        location: {
          latitude: state.currentStoreDetails.address.location._latitude,
          longitude: state.currentStoreDetails.address.location._longitude,
        },
      },
    },
  }

  // Check if market company
  if (state.currentStoreDetails.marketCompany) {
    newOrder.order.marketFee = marketFee
  }

  //Check if discount applied
  if (!state.discount.value) {
    delete newOrder.order.discountApplied
  }

  // Attach Curbside / In-Store fields
  if (
    state.pickupMethod === PICKUP_METHODS.CURBSIDE ||
    state.pickupMethod === PICKUP_METHODS.IN_STORE
  ) {
    if (state.pickupTimeDate) {
      newOrder.order.pickupTimeDate = {
        ...state.pickupTimeDate,
        pickupDate: moment(state.pickupTimeDate?.pickupDate).toDate().toJSON(),
      }
    }
  }

  if (state.pickupMethod === PICKUP_METHODS.DELIVERY) {
    //Attach delivery fields
    newOrder.order.deliveryFee = (state.deliveryFee ?? 0) + marketFee ?? 0

    if (state.deliveryWindow) {
      newOrder.order.deliveryWindow = state.deliveryWindow
    }
    if (state.timeWindow) {
      newOrder.order.timeWindow = state.timeWindow
    }
  }

  // console.log({ newOrder })

  return newOrder
}

const handlePickUpDetailsChange = (state, item) => {
  let updatedState = { ...state }

  switch (item.name) {
    case "pickupMethod":
      updatedState = { ...state, pickupMethod: item.value }
      break
    case "licensePlate":
      updatedState = {
        ...state,
        carDetails: { ...state.carDetails, licensePlate: item.value },
      }
      break
    case "carModel":
      updatedState = {
        ...state,
        carDetails: { ...state.carDetails, carModel: item.value },
      }
      break
    default:
      updatedState = { ...state }
  }

  return updatedState
}

const handlePaymentDetails = (state, item) => {
  let updatedState = { ...state }
  if (item.name === "paymentMethod") {
    updatedState.paymentMethod = item.value
  } else if (item.name === "cashAmount") {
    updatedState.cashAmount = item.value
  }

  return updatedState
}

const cartReducer = (state, action) => {
  switch (action.type) {
    // CREATE ACTIONS
    case ADD_ITEM_TO_CART: {
      return { ...handleUpdateCart(state, action.item) }
    }

    case ADD_DISCOUNT_CODE: {
      return {
        ...state,
        discount: action.item,
      }
    }

    case CREATE_ORDER_OBJECT: {
      return {
        ...state,
        order: handleCreateOrder(state),
      }
    }

    case TOGGLE_SIDEBAR_HIDDEN: {
      return {
        ...state,
        sidebarHidden: !state.sidebarHidden,
      }
    }

    // UPDATE ACTIONS

    case UPDATE_CART_TOTAL: {
      return {
        ...state,
        total: action.item,
      }
    }

    case UPDATE_CURRENT_STORE_DETAILS: {
      return {
        ...state,
        currentStoreDetails: action.item,
      }
    }

    case UPDATE_DELIVERY_ADDRESS: {
      return {
        ...state,
        deliveryAddress: action.item,
      }
    }

    case UPDATE_GUEST_USER_DETAILS: {
      return {
        ...state,
        guestUserDetails: action.item,
      }
    }

    case SET_TIME_WINDOW: {
      return {
        ...state,
        timeWindow: action.item,
      }
    }

    case SET_DELIVERY_WINDOW: {
      return {
        ...state,
        deliveryWindow: action.item,
      }
    }

    case UPDATE_PAYMENT_DETAILS: {
      let newState = handlePaymentDetails(state, action.item)
      return {
        ...newState,
      }
    }

    case UPDATE_PICKUP_DETAILS: {
      let newState = handlePickUpDetailsChange(state, action.item)
      return {
        ...newState,
      }
    }

    case UPDATE_STORE_DELIVERY_FEE: {
      let newState = handleUpdateStoreDeliveryFee(state, action.item)

      return {
        ...newState,
      }
    }

    case UPDATE_USER_AUTH: {
      return {
        ...state,
        userAuth: action.item,
      }
    }
    case UPDATE_USER_DATA: {
      return {
        ...state,
        userData: action.item,
      }
    }

    // DELETE ACTIONS
    case REMOVE_ITEM_FROM_CART: {
      return {
        ...handleRemoveFromCart(state, action.item),
      }
    }

    case CLEAR_CART: {
      return handleClearCart(state)
    }

    case CLEAR_CART_ITEMS: {
      return handleClearCartItems(state)
    }

    case SET_CUSTOM_CHECKOUT: {
      return {
        ...state,
        isCustomCheckout: action.item,
      }
    }
    case UPDATE_PICKUP_WINDOW: {
      return {
        ...state,
        pickupTimeDate: action.item,
      }
    }
    case SET_IS_DELIVERY_DISCOUNT:
      return {
        ...state,
        isDeliveryDiscount: action.isDeliveryDiscount,
      }
    case SET_SPECIAL_NOTES:
      return {
        ...state,
        specialNotes: action.item,
      }
    case UPDATE_MISSING_ITEMS_PREF:
      return {
        ...state,
        missingItemsPreference: action.item,
      }

    default:
      return { ...state }
  }
}

const GlobalContextProvider = ({ children }) => {
  let localState = false
  let appVersion = 0

  if (typeof window !== "undefined") {
    localState = JSON.parse(window.localStorage.getItem("state"))
    appVersion = window.localStorage.getItem("APP_VERSION")
  }

  let storedState =
    process.env.NEXT_PUBLIC_WEB_APP_VERSION === appVersion ? localState : null

  const [state, dispatch] = useReducer(cartReducer, storedState || initialState)

  typeof window !== "undefined" &&
    window.localStorage.setItem(
      "APP_VERSION",
      process.env.NEXT_PUBLIC_WEB_APP_VERSION
    )

  useEffect(() => {
    typeof window !== "undefined" &&
      window.localStorage.setItem("state", JSON.stringify(state))
  }, [state])

  return (
    <GlobalStateContext.Provider value={state}>
      <GloablDispatchContext.Provider value={dispatch}>
        {children}
      </GloablDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext)

  if (context === undefined) {
    throw new Error(
      "useGlobalState must be used within a GlobalContextProvider"
    )
  }
  return context
}

export const useGlobalDisptach = () => {
  const context = useContext(GloablDispatchContext)

  if (context === undefined) {
    throw new Error(
      "useGlobalDispatch must be used within a GlobalContextProvider"
    )
  }

  return context
}

export default GlobalContextProvider
